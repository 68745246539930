import he from 'he';

import { MetaItem } from '@/types/common';

export function parseMeta(fullHead: string): MetaItem[] {
  // TODO! Replace blog domain with the correct one

  const metaElements = (he.decode(fullHead) as string)
    .split('\n')
    .filter(e => e?.startsWith('<meta'));

  return metaElements
    .map(metaElement => {
      // if (metaElement.includes('og:description')) {
      //   return null;
      // }

      const regex = /(\w+)="([^"]*)"/g;
      let match;
      const matches: Record<string, string> = {};

      while (
        (match = regex.exec(metaElement.replaceAll('&quot;', '__QUOT__'))) !==
        null
      ) {
        matches[match[1]] = match[2];
      }

      const { name, property, content } = matches;

      if (
        ['twitter:description', 'og:description'].includes(property) ||
        name === 'description'
      ) {
        return null;
      }

      if (name) {
        return {
          name,
          content
        };
      }

      if (property) {
        return {
          property,
          content
        };
      }

      return null;
    })
    .filter(Boolean);
}
